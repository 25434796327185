import { Link, LinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, Ref } from 'react';

import { RegistryPackageType } from '@/graphql/upbound-graphql';

type Route<P extends { [K in keyof P]: P[K] } = Record<string, unknown>> = (params: P) => string;

type PackageListProps = { packageType?: RegistryPackageType };

type PackageDetailsProps = PackageListProps & {
  packageOwner?: string;
  packageName?: string;
  packageVersion?: string;
};

type PackageDocsProps = PackageDetailsProps & { pageName?: string };

type ResourceDetailsProps = {
  resourceGroup?: string;
  resourceKind?: string;
  resourceVersion?: string;
};

type ResourceDetailsFromRootProps = PackageDetailsProps & ResourceDetailsProps;

type CompositionDetailsProps = {
  compositionName?: string;
  compositionXrdGroup?: string;
  compositionXrdKind?: string;
};

type AccountPageProps = {
  account: string;
};

type RepositoryListProps = {
  repository: string;
};

interface RouteProps {
  accountPage: Route<AccountPageProps>;
  repositoryList: Route<RepositoryListProps>;
  compositionDetails: Route<CompositionDetailsProps>;
  packageList: Route<PackageListProps>;
  packageDetails: Route<PackageDetailsProps>;
  packageDocsTab: Route<PackageDocsProps>;
  resourceDetails: Route<ResourceDetailsProps>;
  resourceDetailsFromRoot: Route<ResourceDetailsFromRootProps>;
  rootIndex: Route;
}

const routes: RouteProps = {
  compositionDetails: ({ compositionName, compositionXrdGroup, compositionXrdKind }) => {
    return `/compositions/${compositionName}/${compositionXrdGroup}/${compositionXrdKind}`;
  },
  packageDetails: ({ packageType, packageOwner, packageName, packageVersion = 'latest' }) => {
    return `/${packageType?.toLowerCase()}s/${packageOwner}/${packageName}/${packageVersion}`;
  },
  packageDocsTab: ({ packageType, packageOwner, packageName, packageVersion = 'latest', pageName }) => {
    const path = `/${packageType?.toLowerCase()}s/${packageOwner}/${packageName}/${packageVersion}/docs`;

    if (!pageName) {
      return path;
    }

    // TODO(hasheddan): this converts docs pages with spaces in the title to use
    // hyphens instead. This behavior should be more explicitly defined in
    // user-facing specifications when docs are generally supported.
    return `${path}/${pageName?.toLowerCase().replace(' ', '-')}`;
  },
  packageList: ({ packageType }) => {
    return `/${packageType?.toLowerCase()}s`;
  },
  resourceDetails: ({ resourceGroup, resourceKind, resourceVersion = 'latest' }) => {
    return `/resources/${resourceGroup}/${resourceKind}/${resourceVersion}`;
  },
  resourceDetailsFromRoot: ({
    packageType,
    packageOwner,
    packageName,
    packageVersion = 'latest',
    resourceGroup,
    resourceKind,
    resourceVersion = '',
  }) => {
    return `/${packageType?.toLowerCase()}s/${packageOwner}/${packageName}/${packageVersion}/resources/${resourceGroup}/${resourceKind}/${resourceVersion}`;
  },
  rootIndex: () => {
    return '/';
  },
  accountPage: ({ account }) => {
    return `/account/${account}`;
  },
  repositoryList: ({ repository }) => {
    return `/${repository}`;
  },
};

type RouteLinkProps = Omit<LinkProps, 'href'>;

function getHref<P>(to: string, params: P, prepend: string | undefined, append: string | undefined) {
  return [prepend, routes[to](params || {}), append].join('');
}

const RouteLink = forwardRef(
  <P extends Params<RouteProps[keyof RouteProps]>>(
    {
      children,
      to,
      params,
      prepend,
      append,
      prefetch,
      scroll,
      shallow,
      ...props
    }: RouteLinkProps & {
      to: keyof RouteProps;
      params?: P;
      prepend?: string;
      append?: string;
      prefetch?: boolean;
      scroll?: boolean;
      shallow?: boolean;
    },
    ref: Ref<HTMLAnchorElement>,
  ) => {
    const router = useRouter();

    if (prefetch === false) {
      return (
        <Link
          {...props}
          ref={ref}
          onClick={() => {
            router.push(getHref(to, params, prepend, append));
          }}
        >
          {children}
        </Link>
      );
    }

    return (
      <NextLink
        passHref={true}
        href={getHref(to, params, prepend, append)}
        scroll={scroll}
        shallow={shallow}
        prefetch={prefetch === true}
        legacyBehavior={true}
      >
        <Link {...props} ref={ref}>
          {children}
        </Link>
      </NextLink>
    );
  },
);

RouteLink.displayName = 'RouteLink';

type ExportedRouteLinkProps = Omit<RouteLinkProps, 'to' | 'params'>;

export type { ExportedRouteLinkProps as RouteLinkProps, RouteProps };

export default RouteLink;
