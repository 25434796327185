import { Box, Flex, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';

import {
  MARKETING_ABOUT_URL,
  MARKETING_PRIVACY_URL,
  MARKETING_TERMS_URL,
  UPBOUND_CONTACT_URL,
  UPBOUND_DOCS_URL,
  UPBOUND_REGISTER_URL_A,
} from '@/utils/constants/urls';

import UpboundLogoAnchor from './UpboundLogoAnchor';

const PageFooter: React.FC<{ noPaddingTop?: boolean }> = ({ noPaddingTop = false }) => {
  const paddingTop = noPaddingTop
    ? 0
    : {
        base: '52px',
        md: '80px',
        xl: '130px',
      };

  return (
    <Box
      width="100%"
      m="0 auto"
      pt={paddingTop}
      pb={{
        base: '52px',
        xl: '70px',
      }}
      overflow="hidden"
    >
      <Box m={`${noPaddingTop ? '0px' : '50px'} 0 50px`} width="100%" height="1px" backgroundColor="white.3" />
      <Flex
        width="100%"
        flexDirection={{ base: 'column', md: 'row' }}
        flexWrap={{ base: 'wrap', xl: 'nowrap' }}
        maxWidth="1440px"
        margin="0 auto"
        px="30px"
      >
        <Flex display={{ base: 'none', xl: 'flex' }} flexDirection="column" width="100%" ml={{ base: '50px', md: '0' }}>
          <UpboundLogoAnchor size="md" variant="black" mb="18px" />
          <Text mb="20px" fontSize="16px" lineHeight="28px">
            <Text as="span" color="black.1">
              Discover the building blocks for your internal cloud platform.
            </Text>
          </Text>
          <Text fontSize="16px" lineHeight="28px" color="black.1">
            © 2022 Upbound, Inc.
          </Text>
        </Flex>
        <Flex flexDirection={{ base: 'column', md: 'row' }} width="100%">
          <Flex flexDirection="column" alignItems="start" mr={{ base: '0', md: '60px' }} mb={{ base: '30px', md: '0' }}>
            <Text as="span" lineHeight="25px" fontSize="18px" fontWeight={900} mb="24px">
              Solutions
            </Text>
            <NextLink href="/providers" passHref={true} legacyBehavior={true}>
              <Link fontSize="16px" lineHeight="22px" mb="26px" _hover={{ opacity: 0.7 }}>
                Providers
              </Link>
            </NextLink>
            <NextLink href="/configurations" passHref={true} legacyBehavior={true}>
              <Link fontSize="16px" lineHeight="22px" _hover={{ opacity: 0.7 }}>
                Configurations
              </Link>
            </NextLink>
          </Flex>
          <Flex flexDirection="column" alignItems="start" mr={{ base: '0', md: '75px' }} mb={{ base: '30px', md: '0' }}>
            <Text as="span" lineHeight="25px" fontSize="18px" fontWeight={900} mb="24px">
              Learn
            </Text>
            <Link href={UPBOUND_DOCS_URL} fontSize="16px" lineHeight="22px" mb="26px" _hover={{ opacity: 0.7 }}>
              Documentation
            </Link>
            <Link
              href={UPBOUND_REGISTER_URL_A}
              fontSize="16px"
              lineHeight="22px"
              whiteSpace="nowrap"
              _hover={{ opacity: 0.7 }}
            >
              Try for Free
            </Link>
          </Flex>
          <Flex flexDirection="column" alignItems="start" mr={{ base: '0', md: '70px' }} mb={{ base: '30px', md: '0' }}>
            <Text as="span" lineHeight="25px" fontSize="18px" fontWeight={900} mb="24px">
              Company
            </Text>
            <Link href={MARKETING_ABOUT_URL} fontSize="16px" lineHeight="22px" mb="26px" _hover={{ opacity: 0.7 }}>
              About
            </Link>
            <Link
              href={UPBOUND_CONTACT_URL}
              fontSize="16px"
              lineHeight="22px"
              whiteSpace="nowrap"
              _hover={{ opacity: 0.7 }}
            >
              Contact Us
            </Link>
          </Flex>
          <Flex flexDirection="column" alignItems="start" mb={{ base: '50px', md: '0' }}>
            <Text as="span" lineHeight="25px" fontSize="18px" fontWeight={900} mb="24px">
              More
            </Text>
            <Link
              href={MARKETING_PRIVACY_URL}
              fontSize="16px"
              lineHeight="22px"
              mb="26px"
              whiteSpace="nowrap"
              _hover={{ opacity: 0.7 }}
            >
              Privacy Policy
            </Link>
            <Link
              href={MARKETING_TERMS_URL}
              fontSize="16px"
              lineHeight="22px"
              whiteSpace="nowrap"
              _hover={{ opacity: 0.7 }}
            >
              Terms & Conditions
            </Link>
          </Flex>
        </Flex>
        <Flex
          display={{ base: 'none', md: 'flex', xl: 'none' }}
          flexDirection="row"
          alignItems="start"
          mr="45px"
          mt="70px"
          width="100%"
        >
          <UpboundLogoAnchor />
          <Text fontSize="16px" lineHeight="35px" color="black.1" ml="30px">
            © 2022 Upbound, Inc.
          </Text>
        </Flex>
        <Flex display={{ base: 'flex', md: 'none' }} flexDirection="row" alignItems="start" mr="45px" width="100%">
          <Flex flexDirection="column" width="100%">
            <UpboundLogoAnchor size="md" mb="18px" />
            <Text mb="20px" fontSize="16px" lineHeight="28px">
              <Text as="span" color="black.1">
                Discover the building blocks
              </Text>
              <Text as="span" display="block" color="black.1">
                for your internal cloud platform.
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PageFooter;
