import {
  CONFIG_ACCOUNTS_HOST,
  CONFIG_CONSOLE_HOST,
  CONFIG_HOST_NAME,
  CONFIG_MARKETING_HOST,
} from '@/utils/constants/config';

const MARKETPLACE_URL = `https://${CONFIG_HOST_NAME}`;
const CONSOLE_URL = `https://${CONFIG_CONSOLE_HOST}`;

const accountsUrl = (path?: string | undefined, returnToPath?: string) =>
  `https://${CONFIG_ACCOUNTS_HOST}${path}?targetProperty=marketplace${
    returnToPath ? `&returnTo=${encodeURIComponent(`${MARKETPLACE_URL}${returnToPath}`)}` : ''
  }`;
const accountsConsoleUrl = (path?: string | undefined, returnToPath?: string) =>
  `https://${CONFIG_ACCOUNTS_HOST}${path}?targetProperty=console${
    returnToPath ? `&returnTo=${encodeURIComponent(`${CONSOLE_URL}${returnToPath}`)}` : ''
  }`;
const consoleUrl = (path?: string | undefined) => `${CONSOLE_URL}${path}`;

const accountsLoginUrl = (returnToPath?: string) => accountsUrl('/login', returnToPath);
const accountsLogoutUrl = (returnToPath?: string) => accountsUrl('/logout', returnToPath);
const accountsRegisterUrl = (returnToPath?: string) => accountsUrl('/register', returnToPath);
const accountsSettingsUrl = (returnToPath?: string) => accountsUrl('/settings', returnToPath);

const accountsConsoleSelectOrgUrl = (returnToId?: string) =>
  !returnToId ? accountsConsoleUrl(`/login`) : accountsConsoleUrl('/login', `/selectOrg?returnToId=${returnToId}`);

const consoleSelectOrgUrl = (returnToId?: string) => consoleUrl(`/selectOrg?returnToId=${returnToId}`);

const consoleSelectOrgUrlWithConfigurationId = (configurationId: string) =>
  consoleSelectOrgUrl(UPBOUND_CONSOLE_RETURN_TO_ID_CREATE_CONFIGURATION) +
  `&configurationId=${encodeURIComponent(configurationId)}`;

const UPBOUND_DOCS_URL = `https://docs.${CONFIG_MARKETING_HOST}`;
const UPBOUND_DOCS_PUBLISH_URL = `${UPBOUND_DOCS_URL}/upbound-marketplace/packages/`;
const UPBOUND_DOCS_PROVIDERS_URL = `${UPBOUND_DOCS_URL}/providers/`;
const UPBOUND_DOCS_AUTH_URL = `${UPBOUND_DOCS_URL}/upbound-marketplace/authentication/`;
const UPBOUND_DOCS_CONNECT_TO_MCP_URL = `${UPBOUND_DOCS_URL}/concepts/mcp/#connect-directly-to-your-mcp`;

const MARKETING_PRIVACY_URL = `https://${CONFIG_MARKETING_HOST}/privacy`;
const MARKETING_TERMS_URL = `https://${CONFIG_MARKETING_HOST}/terms`;
const MARKETING_ABOUT_URL = `https://${CONFIG_MARKETING_HOST}/about`;
const MARKETING_FAQ_URL = `https://${CONFIG_MARKETING_HOST}/faq`;
const MARKETING_PARTNERS_URL = `https://${CONFIG_MARKETING_HOST}/partners`;
const UPBOUND_LICENSES_URL = 'https://licenses.upbound.io';
const UPBOUND_SLACK_URL = 'https://slack.crossplane.io';
const UPBOUND_CONTACT_URL = `https://${CONFIG_MARKETING_HOST}/contact`;
const UPBOUND_MAIL_URL = 'mailto:info@upbound.io';
const UPBOUND_JOBS_URL = 'https://boards.greenhouse.io/upbound';
const UPBOUND_REGISTER_URL_A = `https://${CONFIG_MARKETING_HOST}/register/a`;

const CROSSPLANE_DOCS_URL = 'https://crossplane.io/docs';

// ref: https://github.com/upbound/upbound-frontend/pull/3379
const UPBOUND_CONSOLE_RETURN_TO_ID_CREATE_CONFIGURATION = 'createConfiguration';

export {
  MARKETPLACE_URL,
  UPBOUND_DOCS_URL,
  UPBOUND_DOCS_PUBLISH_URL,
  UPBOUND_DOCS_PROVIDERS_URL,
  UPBOUND_DOCS_AUTH_URL,
  UPBOUND_DOCS_CONNECT_TO_MCP_URL,
  accountsUrl,
  accountsConsoleUrl,
  accountsLoginUrl,
  accountsLogoutUrl,
  accountsRegisterUrl,
  accountsSettingsUrl,
  accountsConsoleSelectOrgUrl,
  consoleUrl,
  consoleSelectOrgUrl,
  consoleSelectOrgUrlWithConfigurationId,
  MARKETING_PRIVACY_URL,
  MARKETING_TERMS_URL,
  MARKETING_ABOUT_URL,
  MARKETING_FAQ_URL,
  MARKETING_PARTNERS_URL,
  UPBOUND_LICENSES_URL,
  UPBOUND_SLACK_URL,
  UPBOUND_CONTACT_URL,
  UPBOUND_MAIL_URL,
  UPBOUND_JOBS_URL,
  CROSSPLANE_DOCS_URL,
  UPBOUND_REGISTER_URL_A,
  UPBOUND_CONSOLE_RETURN_TO_ID_CREATE_CONFIGURATION,
};
