import { BoxProps } from '@chakra-ui/react';
import { RefObject } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import PageContent from './PageTemplate/PageContent';
import PageFooter from './PageTemplate/PageFooter';
import PageHeader from './PageTemplate/PageHeader';
import PageTitle from './PageTemplate/PageTitle';

const PageTemplate: React.FC<
  BoxProps & {
    title?: string;
    isSearchVisible?: boolean;
    mobileRef?: RefObject<HTMLDivElement>;
    packageType?: RouteParams.PackageType;
    noFooterPaddingTop?: boolean;
    description?: string | null;
    image?: string | null;
  }
> = ({
  children,
  title,
  isSearchVisible = true,
  mobileRef,
  packageType,
  noFooterPaddingTop,
  description,
  image,
  ...props
}) => {
  return (
    <ErrorBoundary fallback={<div>Oh no!</div>}>
      <PageTitle title={title} description={description} image={image} />
      <PageContent {...props}>
        <PageHeader isSearchVisible={isSearchVisible} mobileRef={mobileRef} packageType={packageType} />
        {children}
      </PageContent>
      <PageFooter noPaddingTop={noFooterPaddingTop} />
    </ErrorBoundary>
  );
};

export default PageTemplate;
